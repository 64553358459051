<template>
  <el-dialog :visible.sync="visible" title="选择用户" :close-on-click-modal="false" :before-close="close" @closed="closed" class="yt-dialog">
    <div class="container">
      <div class="paper-left">
        <el-menu class="yt-menu" style="background-color: #0000 !important" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
          <el-menu-item index="1">
            班级
          </el-menu-item>
          <el-menu-item index="2">
            标签
          </el-menu-item>
        </el-menu>
        <div class="list-container">
          <el-tree
            :data="treeData"
            :props="treeDataProps"
            node-key="departmentId"
            ref="tree"
            :filter-node-method="filterNode"
            show-checkbox
            check-strictly
            @check="handleClickTreeNode"
            @node-click="handleNodeClick"
          >
            <div class="tree-node" slot-scope="{ node, data }">
              <el-tooltip effect="dark" :content="node.label" :disabled="node.label.length < 10" placement="top-start">
                <div class="el-tree-node__label">{{ node.label.length > 10 ? node.label.substring(0, 10) + '...' : node.label }}</div>
              </el-tooltip>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="paper-right">
        <div style="display: flex;justify-content: flex-start;margin-bottom: 15px">
          <el-input
            style="width: 300px;"
            size="small"
            placeholder="请输入姓名(回车搜索)"
            v-model="searchForm.userName"
            @keyup.native.enter="searchUser()"
          />
        </div>
        <div class="overStyle" style="max-height: 400px; overflow-y: auto">
          <el-table
            ref="deUsersTable"
            :data="depaUsers"
            style="width: 100%"
            :row-key="getRowKeys"
            border
            @selection-change="handleSelect"
            @select="update"
            @select-all="selectAll"
          >
            <el-table-column type="selection" :selectable="selectable" :reserve-selection="true" width="60" align="center" />
            <el-table-column label="学号" align="center" prop="jobNumber" />
            <el-table-column label="姓名" align="center" prop="realName" />
          </el-table>
        </div>
        <div style="margin-top: 20px;display: flex;justify-content: space-between">
          <Page size="small" show-total @on-change="handlePageNum" @on-page-size-change="handlePageSize" show-sizer :total="deTotal"> </Page>
        </div>
      </div>
    </div>
    <div class="footer" slot="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" :loading="loading" @click="ok">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import departmentApi from '@api/department'
import tagApi from '@api/tag'
import tabApi from '@api/tab'
import staffApi from '@api/staff'
import examApi from '@api/exam'
export default {
  name: 'StaffSelectDialog',
  data() {
    return {
      visible: false,
      loading: false,
      activeIndex: '1',
      treeData: [],
      treeDataProps: {
        label: 'name',
        children: 'children'
      },
      postData: [],
      tagData: [],
      tagDataCopy: [],
      depaUsers: [],
      deTotal: 0,
      searchForm: {
        departmentId: '',
        userName: '',
        page: 0,
        size: 10
      },
      postDataSelect: [],
      tagDataSelect: [],
      studentIds: [],
      studentIdsCopy: [],
      asd: true,
      jump: true,
      examId: '',
      userId: []
    }
  },
  methods: {
    open(bool, examId, data) {
      this.activeIndex = '1'
      this.postDataSelect = data.departmentIds
      this.tagDataSelect = data.tagIds
      this.studentIdsCopy = data.userIds
      this.jump = bool
      this.examId = examId
      this.visible = true
      this.getDepartmentsTree()
      this.getTagTree()
      this.$nextTick(() => {
        this.$refs['tree'].setCheckedKeys(this.postDataSelect)
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
    },
    ok() {
      this.loading = true
      if (this.jump) {
        this.$router.push({
          path: '/manage/exam/examReport',
          // path: '/manage/exam/result',
          query: {
            departmentIds: this.postDataSelect,
            examId: this.examId,
            tagIds: this.tagDataSelect,
            userIds: this.studentIdsCopy
          }
        })
      } else {
        let payload = {
          departmentIds: this.postDataSelect,
          tagIds: this.tagDataSelect,
          userIds: this.studentIdsCopy
        }
        examApi.selectUserNum(payload).then(res => {
          this.userId = res.res.userId
          this.$emit('success', this.userId, payload)
          this.$refs.deUsersTable.clearSelection()
        })
      }
      this.visible = false
    },
    update(selection, row) {
      let flag = true
      if (selection.length > 0) {
        selection.forEach(item => {
          if (item.userId === row.userId) {
            flag = false
          }
        })
      }
      //true 取消 false 勾选
      if (flag) {
        if (this.studentIdsCopy.length > 0) {
          this.studentIdsCopy.forEach((item, index) => {
            if (item === row.userId) {
              this.studentIdsCopy.splice(index, 1)
            }
          })
        }
      } else {
        if (!this.studentIdsCopy.includes(row.userId)) {
          this.studentIdsCopy.push(row.userId)
        }
      }
    },
    selectAll(selection) {
      if (selection.length > 0) {
        selection.forEach(item => {
          if (!this.studentIdsCopy.includes(item.userId)) {
            this.studentIdsCopy.push(item.userId)
          }
        })
      } else {
        if (this.studentIdsCopy.length > 0) {
          this.depaUsers.forEach(t => {
            this.studentIdsCopy.forEach((item, index) => {
              if (item === t.userId) {
                this.studentIdsCopy.splice(index, 1)
              }
            })
          })
        }
      }
    },
    selectMenu(index) {
      this.activeIndex = index
      this.searchForm.pageNum = 0
      this.searchForm.pageSize = 10
      this.deTotal = 0
      this.searchForm.userName = ''
      this.depaUsers = []
      if (index === '1') {
        this.treeData = this.postData
        this.handleNodeClick(this.treeData[0])
        this.$nextTick(() => {
          this.$refs['tree'].setCheckedKeys(this.postDataSelect)
        })
      } else {
        this.treeData = this.tagDataCopy
        this.handleNodeClick(this.treeData[0])
        this.$nextTick(() => {
          this.$refs['tree'].setCheckedKeys(this.tagDataSelect)
        })
      }
    },
    filterNode(value, data) {
      //知识点树过滤
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    handleClickTreeNode(data, checkData) {
      if (this.activeIndex === '1') {
        let postDataSelect = []
        checkData.checkedNodes.forEach(item => {
          postDataSelect.push(item.departmentId)
        })
        this.postDataSelect = postDataSelect
        this.asd = true
        if (data.departmentId === this.searchForm.departmentId) {
          this.$refs.deUsersTable.clearSelection()
          this.postDataSelect.forEach(item => {
            if (item === this.searchForm.departmentId) {
              this.$refs.deUsersTable.toggleAllSelection()
              this.asd = false
            }
          })
        }
      } else {
        let tagDataSelect = []
        checkData.checkedNodes.forEach(item => {
          tagDataSelect.push(item.departmentId)
        })
        this.tagDataSelect = tagDataSelect
        this.asd = true
        if (data.departmentId === this.searchForm.departmentId) {
          this.$refs.deUsersTable.clearSelection()
          this.tagDataSelect.forEach(item => {
            if (item === this.searchForm.departmentId) {
              this.$refs.deUsersTable.toggleAllSelection()
              this.asd = false
            }
          })
        }
      }
    },
    selectable() {
      return this.asd
    },
    handleNodeClick(data) {
      this.searchForm.departmentId = data.departmentId
      this.searchForm.pageNum = 0
      this.searchForm.pageSize = 10
      this.deTotal = 0
      this.searchForm.userName = ''
      this.searchUser()
    },
    getDepartmentsTree() {
      departmentApi.getDepartmentsTree().then(res => {
        if (res.code === 0) {
          this.postData = res.res.children
          this.treeData = this.postData
          this.handleNodeClick(this.treeData[0])
        }
      })
    },
    getTagTree() {
      tagApi.getUserTags().then(res => {
        if (res.code === 0) {
          this.tagData = res.res
          this.tagDataCopy = []
          this.tagData.forEach(item => {
            this.tagDataCopy.push({
              name: item.name,
              departmentId: item.tagId
            })
          })
        }
      })
    },
    getRowKeys(row) {
      return row.userId
    },
    handleSelect(val) {
      this.studentIds = []
      val.forEach(v => {
        this.studentIds.push(v.userId)
      })
    },
    searchUser() {
      if (this.activeIndex === '1') {
        let payload = {
          page: this.searchForm.page,
          size: this.searchForm.size,
          userName: this.searchForm.userName,
          departmentIds: [this.searchForm.departmentId]
        }
        staffApi.searchUser(payload).then(res => {
          this.depaUsers = res.res.data
          this.depaUsers.forEach((item, index) => {
            this.studentIdsCopy.forEach(t => {
              if (item.userId === t) {
                this.$refs.deUsersTable.toggleRowSelection(this.depaUsers[index], true)
              }
            })
          })
          this.deTotal = res.res.total
          this.asd = true
          if (this.postDataSelect) {
            this.postDataSelect.forEach(item => {
              if (this.searchForm.departmentId === item) {
                this.$refs.deUsersTable.clearSelection()
                this.$refs.deUsersTable.toggleAllSelection()
                this.asd = false
              }
            })
          }
        })
      } else {
        let payload = {
          page: this.searchForm.page,
          size: this.searchForm.size,
          userName: this.searchForm.userName,
          tagIds: [this.searchForm.departmentId]
        }
        tabApi.searchUser(payload).then(res => {
          this.depaUsers = res.res.data
          this.depaUsers.forEach((item, index) => {
            this.studentIdsCopy.forEach(t => {
              if (item.userId === t) {
                this.$refs.deUsersTable.toggleRowSelection(this.depaUsers[index], true)
              }
            })
          })
          this.deTotal = res.res.total
          this.asd = true
          if (this.tagDataSelect) {
            this.tagDataSelect.forEach(item => {
              if (this.searchForm.departmentId === item) {
                this.$refs.deUsersTable.clearSelection()
                this.$refs.deUsersTable.toggleAllSelection()
                this.asd = false
              }
            })
          }
        })
      }
    },
    handlePageNum(val) {
      this.searchForm.page = val - 1
      this.searchUser()
    },
    handlePageSize(val) {
      this.searchForm.page = 0
      this.searchForm.size = val
      this.searchUser()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../theme/variables';
@import '~@/theme/paperDetail';

.container {
  .flexStyle(flex, center, center);
  //min-width: 1080px;
  width: 100%;
  .paper-left {
    height: 520px;
    width: calc(100% / 4 + 91px);
    //width: 35%;
    background: #ffffff;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    .input {
      padding: 12px 10px 10px;
    }
    .yt-menu {
      display: flex;
      justify-content: center;
      margin-left: 0 !important;
    }
    .el-input__icon {
      cursor: pointer;
      width: 37px;
      line-height: 67px;
    }
    .paper-list {
      //   padding: 0 10px;
      overflow-y: auto;
      .paper-info {
        height: 57px;
        padding: 0 10px;
        cursor: pointer;
        border-bottom: 1px solid #f0f0f0;
        p {
          width: 239px;
          font-size: 12px;
          font-weight: bold;
          line-height: 28px;
          color: #000000;
          text-align: left;
          min-height: 28px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .paper-fraction {
          .flexStyle(flex, flex-start, center);
          span {
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            color: #888888;
            &:first-child {
              padding-right: 10px;
            }
          }
        }
        &:hover {
          background: #edf4ff;
          p,
          span {
            color: #448bff;
          }
        }
      }
    }
  }
  .menu {
    p,
    span {
      color: #448bff !important;
    }
  }
  .paper-right {
    height: 520px;
    // width: 75%;
    // max-width: 771px;
    width: 100%;
    //max-width: 950px;
    // min-height: 520px;
    margin-left: 10px;
    background: #ffffff;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    padding: 20px 0 0 20px;
    p {
      margin-bottom: 16px;
      font-size: 14px;
      /*font-weight: bold;*/
      line-height: 28px;
      color: #000000;
      text-align: left;
    }

    .yt-question-info {
      margin-top: 0;
      overflow-y: auto;
      .body {
        width: calc(100% - 10px) !important;
      }
      .tk-item {
        width: 100% !important;
      }
      ::v-deep img {
        width: 100% !important;
      }
    }
    .filter-container {
      //   border-bottom: 1px solid #e2e4e8;
      position: relative;
      .pack-up {
        position: absolute;
        width: 53px !important;
        height: 25px;
        line-height: 25px;
        background: #f7f7f7;
        border-radius: 13px;
        border: 1px solid #e6e6e6;
        cursor: pointer;
        right: 25px;
        top: 13px;
        .flexStyle(flex, flex-start, center);
        p {
          margin-left: 8px;
          font-size: @small;
          width: 24px !important;
          user-select: none; //不被选中
        }

        svg {
          width: 9px;
          height: 14px;
          margin-left: 4px;
          margin-top: 14px;
          margin-right: 6px;
          transition: all 0.2s ease-in-out;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        .active {
          -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
        }
      }
      .row {
        .flexStyle(flex, flex-start);
        margin-bottom: 10px;
        height: 20px;
        color: #000000;
        &:last-child {
          margin-bottom: 0;
        }
        .label {
          font-size: @small;
          margin-right: 20px;
          height: 20px;
          font-weight: 400;
          line-height: 22px;
          color: #000000;
        }
        .item {
          height: 20px;
          line-height: 20px;
          margin: 0 8px;
          padding: 0 15px;
          font-size: @small;
          cursor: pointer;
        }
        .default-item {
          &:hover {
            border-radius: 11px;
            background: #448bff;
            color: #ffffff;
          }
        }
        .active {
          border-radius: 11px;
          background: #448bff;
          color: #ffffff;
        }
        .checkbox-container {
          .flexStyle(flex, flex-start, center);
          border-left: 1px solid #d8d8d8;
          margin-left: 17px;
          .el-checkbox {
            margin-left: 40px;
            margin-right: 0;
            ::v-deep .el-checkbox__label {
              font-size: @small;
              padding-left: 6px;
              color: #000000 !important;
            }
          }
        }
        .other-item {
          padding: 0;
          margin: 0 22px 0 8px;
          -moz-user-select: none; /*火狐*/
          -webkit-user-select: none; /*webkit浏览器*/
          -ms-user-select: none; /*IE10*/
          user-select: none; //禁止双击选中文字
          svg {
            color: #a6a6a6;
          }
        }
        .asc {
          transform: rotate(180deg);
        }
        .select {
          font-size: @small;
          &:hover {
            color: #448bff;
            i {
              transform: rotate(180deg);
            }
          }
        }
        .el-dropdown {
          .flexStyle(flex, flex-start, center);
          height: 20px;
          line-height: 20px;
          color: #000000;
        }
        .el-icon-arrow-down {
          font-size: @small;
          color: #999999 !important;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}
.is-empty {
  height: 100%;
  .flexStyle(flex, center, center);
  flex-direction: column;
  p {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #666666 !important;
    margin-bottom: 0 !important;
  }
}
.prompt {
  .flexStyle(flex, center, center);
  flex-direction: column;
  svg {
    fill: #ff5050;
    margin-right: 5px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
  }
  .format {
    .flexStyle(flex, center, center);
    span {
      white-space: nowrap;
      margin-right: 12px;
    }
    .input {
      width: 332px;
      height: 37px;
      margin-left: 12px;
    }
    ::v-deep .el-input__inner {
      height: 37px;
      line-height: 37px;
    }
    &:first-child {
      margin-bottom: 30px;
    }
  }
}
.dialog-footer {
  .flexStyle(flex, center, center);
  .el-button {
    width: 80px;
  }
}
.stem {
  ::v-deep pre {
    white-space: normal !important;
  }
}
.list-container {
  height: calc(100% - 56px);
  overflow-y: auto;
}
::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner::after {
  cursor: not-allowed;
  border-color: #c0c4cc;
  transform: rotate(45deg) scaleY(1);
}
::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner {
  background-color: #409eff;
  border-color: #409eff;
  cursor: not-allowed;
}
</style>
