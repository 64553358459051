export const EXAM_STATUS = {
  NEWLY: {
    value: 1,
    label: '未开始'
  },
  UNSTARTED: {
    value: 2,
    label: '进行中'
  },
  ONGOING: {
    value: 3,
    label: '已结束'
  },
  UNKNOWN: {
    value: 4,
    label: '未知状态'
  }
}

/**
 * 获取考试状态中文描述，默认"已发布"
 * @param value
 * @returns {*}
 */
export const getStatusByValue = value => {
  const statusList = Object.keys(EXAM_STATUS)
  for (const status of statusList) {
    if (EXAM_STATUS[status].value === value) {
      return EXAM_STATUS[status]
    }
  }
  return EXAM_STATUS.UNKNOWN
}
